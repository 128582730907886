<template>
  <div class="single-article-page">
    <loader full :visible="isLoading" />
    <article class="single-article" v-if="singleVideo && !isLoading && !error">
      <div class="single-article__details">
        <router-link
          :to="`/${$i18n.locale}/videos`"
          class="single-article__category"
        >
          {{ $t('video') }}
        </router-link>
        <div class="single-article__detail time">
          {{ $date(singleVideo.published_at).format('HH:mm') }}
        </div>
        <div class="single-article__detail date">
          {{ $date(singleVideo.published_at).format('DD.MM.YYYY') }}
        </div>
      </div>
      <h2 class="single-article__title">
        {{ singleVideo[`title_${$i18n.locale}`] }}
      </h2>
      <div class="single-article__content">
        <div class="image-wrapper">
          <img
            :src="baseUrl + singleVideo.thumbnail.url"
            :alt="singleVideo.thumbnail.alternativeText"
          />
          <div class="image-wrapper__hint">
            Фото: {{ singleVideo.thumbnail.alternativeText }}
          </div>
        </div>
        <video
          v-if="singleVideo[`video_${$i18n.locale}`]"
          :src="baseUrl + singleVideo[`video_${$i18n.locale}`].url"
          controls
        ></video>
        <div v-html="singleVideo[`content_${$i18n.locale}`]"></div>
      </div>
    </article>
    <div class="not-found" v-else-if="error && !isLoading">
      <b-row>
        <b-col cols="12" md="5" data-aos="fade-up">
          <h2 class="mb-4">{{ $t('404_error') }}</h2>
          <p class="mb-4 not-found-desc">
            {{ $t('404_desc') }}
          </p>
          <router-link
            class="button button--default button--block"
            :to="'/' + $i18n.locale"
          >
            {{ $t('return_to_main') }}
          </router-link>
        </b-col>
      </b-row>
    </div>

    <div class="recommendation mt-5">
      <h3 class="mb-3">{{ $t('recommend') }}</h3>
      <b-row>
        <b-col
          v-for="news in recommendedNews"
          :key="news.id"
          cols="12"
          md="6"
          class="my-3"
        >
          <card-item :item="news" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import CardItem from '@/components/cards/CardItem.vue'
import Loader from '@/components/Loader'
import marked from 'marked'

export default {
  components: {
    CardItem,
    Loader
  },
  data: () => ({
    isLoading: false,
    error: false,
    recommendedNews: []
  }),
  computed: {
    singleVideo() {
      return this.$store.state.newsModule.singleVideo
    },
    compiltedMarkdown() {
      return marked(this.singleVideo?.content_uz)
    }
  },
  created() {
    this.$store
      .dispatch('fetchNews', { _limit: 6 })
      .then(news => (this.recommendedNews = news))
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.isLoading = true
        this.$store
          .dispatch('fetchSingleVideo', this.$route.params.id)
          .then(() => {
            this.error = false
          })
          .catch(() => {
            this.error = true
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }
  }
}
</script>
